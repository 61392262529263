import axios from "axios";

export default {

  login(user, headers){
    return axios.post(`/login`, user, headers);
  },

  getInfoUser(data, headers){
    return axios.post(`/info_usr`, data, {headers});
  },


  getStatusImage(payload, headers){
    return axios.get(`/${payload.option}-img/${payload.image}`, {headers});
  },

  getAllInfo(option, headers){
    return axios.get(`/${option}`, {headers});
  },
  getInfoById(payload, headers){
    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
 
  search(data, headers){
    return axios.get(`/buscar/${data.normalizedOpt}/${data.search}`, {headers});
  },

  editImage(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/${payload.option}/upload/image/${payload.id}`, payload.item, {headers} );
  },

  //metodos de eliminar, editar y agregar
  deleteItem(payload,token){
    return axios.delete(`/${payload.option}/delete`, {
        data: {
          id:payload.id
        },
        headers: {
          Authorization: token 
        } 
      });
  },
  
  editItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/${payload.option}/update`, payload.item, {headers} );
  },

  addItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/${payload.option}/create`, payload.item, {headers});
  },


  resendCode(user, headers){
    return axios.post(`/reenviar-codigo`, user, headers);
  },

  addSuscriptor(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/subs/user/suscribe`, payload, {headers});
  },

  

};